import React from 'react';
import { AntDConfigProvider } from '@sharefiledev/antd-config';
import type { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Example } from './Example';

export function setup(app: PiletApi) {
	(window as any).SF_piletexecuted = true;

	app.registerPage('/examplepiletroute', () => (
		<AntDConfigProvider>
			<Example />
		</AntDConfigProvider>
	));
}
