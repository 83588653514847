import * as React from 'react';
import {
	Button,
	Flex,
	Form,
	Input,
	Modal,
	notification,
	Space,
	Tabs,
	TabsProps,
	Typography,
} from 'antd';

export function Example() {
	const { Title, Text, Link } = Typography;

	// Notifications
	const [api, contextHolder] = notification.useNotification();
	const openNotificationWithIcon = () => {
		api['success']({
			message: 'Notification Title',
			description:
				'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
		});
	};

	// Tabs
	const tabItems: TabsProps['items'] = [
		{
			key: '1',
			label: 'Tab 1',
			children: 'Content of Tab Pane 1',
		},
		{
			key: '2',
			label: 'Tab 2',
			children: 'Content of Tab Pane 2',
		},
		{
			key: '3',
			label: 'Tab 3',
			children: <strong>Content of Tab Pane 3</strong>,
		},
	];

	// Modal
	const [isModalOpen, setIsModalOpen] = React.useState(false);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = () => {
		setIsModalOpen(false);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	// Modal in Form
	const [open, setOpen] = React.useState(false);

	const onCreate = (values: any) => {
		// eslint-disable-next-line no-console
		console.log('Received values of form: ', values);
		setOpen(false);
	};

	return (
		<Space direction="vertical">
			<Title>Example Pilet</Title>
			{contextHolder}
			<Flex vertical={false} gap="small">
				<Text>
					Below you can find a few examples of antd components. The documentation for antd
					and how to use their components can be found
				</Text>
				<Link
					href="https://ant.design/components/overview"
					target="_blank"
					rel="noopener noreferrer"
				>
					here.
				</Link>
			</Flex>
			<Button type="primary" onClick={() => openNotificationWithIcon()}>
				Click
			</Button>
			<Space direction="horizontal">
				<Tabs defaultActiveKey="1" items={tabItems} />
			</Space>
			<Title level={3}>Modals</Title>
			<Space direction="horizontal">
				<Button type="primary" onClick={showModal}>
					Open Modal
				</Button>
				<Modal
					title="Basic Modal"
					open={isModalOpen}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<p>Some contents...</p>
					<p>Some other contents...</p>
					<p>Some even more other contents...</p>
				</Modal>
				<Button type="default" onClick={() => setOpen(true)}>
					Open Form in Modal
				</Button>
				<FormInModal open={open} onCreate={onCreate} onCancel={() => setOpen(false)} />
			</Space>
		</Space>
	);
}

interface Values {
	title: string;
	description: string;
	modifier: string;
}

interface FormInModalProps {
	open: boolean;
	onCreate: (values: Values) => void;
	onCancel: () => void;
}

const FormInModal: React.FC<FormInModalProps> = ({ open, onCreate, onCancel }) => {
	const [form] = Form.useForm();
	return (
		<Modal
			open={open}
			title="Create a new collection"
			okText="Create"
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={() => {
				form
					.validateFields()
					.then(values => {
						form.resetFields();
						onCreate(values);
					})
					.catch(info => {
						// eslint-disable-next-line no-console
						console.log('Validate Failed:', info);
					});
			}}
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{ modifier: 'public' }}
			>
				<Form.Item
					name="title"
					label="Title"
					rules={[{ required: true, message: 'Please input the title of collection!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item name="description" label="Description">
					<Input type="textarea" />
				</Form.Item>
			</Form>
		</Modal>
	);
};
